import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";

import Prism from "prismjs";
import "prismjs/components/prism-ocaml";
import "prismjs/components/prism-haskell";

import Layout from "@components/layout";
import DarkImg from "@components/DarkImg";
import CallToAction from "@components/CallToAction";

import "@components/prose.css";
import "./post.css";
import "katex/dist/katex.min.css";

const moment = require("moment");

export default function Template({ pageContext }) {
  React.useEffect(() => Prism.highlightAll());

  let { title, date, body, card, excerpt } = pageContext;

  const context = {
    location: typeof window !== "undefined" && window.location,
    ...pageContext
  };

  date = moment(date).format("MMMM Do, YYYY");
  const components = {
    DarkImg: DarkImg,
    CallToAction: ({ children, ...props }) => (
      <CallToAction context={context} {...props}>
        {children}
      </CallToAction>
    )
  };

  const seo = { card: card, description: excerpt };

  return (
    <Layout title={title} subpath={{ text: "posts" }} seo={seo}>
      <div className="wrapper blog-wrapper prose-container">
        <div className="inner">
          <div className="header">
            <h1 className="title">{title}</h1>
            <h2 className="subtitle">{date}</h2>
          </div>
          <div className="post-content">
            <MDXProvider components={{ ...components }}>
              <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
          </div>
        </div>
      </div>
    </Layout>
  );
}
